import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartBorder } from '@fortawesome/free-regular-svg-icons';

import { red } from 'theme/colors';

import useStateWithLocalStorage from 'utils/wishList';

import { BtnWrapper } from './FavComponent.styles';

export const useLoaded = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => setLoaded(true), []);
  return loaded;
};

const FavComponent = (props) => {
  //PROPS
  const { slug } = props;
  //PROPS

  const loaded = useLoaded();

  const [value, setValue] = useStateWithLocalStorage(
    props.amb ? 'wishA' : 'wish'
  );

  const onClick = (item) => {
    if (typeof window !== 'undefined') {
      if (!value.filter((e) => e.slug === item.slug)[0]) {
        let newV = [...value, item];

        setValue(newV);
      } else {
        let arr = value.filter((e) => {
          return e.slug !== item.slug;
        });

        setValue(arr);
      }
    }
  };

  return null;

  return (
    <BtnWrapper onClick={() => onClick(props)} className="favComp">
      {loaded && value.filter((e) => e.slug === slug)[0] ? (
        <FontAwesomeIcon icon={faHeart} color={red.default} />
      ) : (
        <FontAwesomeIcon icon={faHeartBorder} color={red.default} />
      )}
    </BtnWrapper>
  );
};

export default FavComponent;
