import React, { useState, useRef } from 'react';

import Slider from 'react-slick';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { black } from 'theme/colors';

import { Wrapper, Prev, Next } from './Gallery.styles';

const Gallery = (props) => {
  //PROPS
  const { images, title } = props;
  //PROPS

  //REF
  const slider = useRef(null);
  //REF

  //STATE
  const [focus, setFocus] = useState(0);
  //STATE

  //FUNCS
  const nextSlide = () => {
    slider.current.slickNext();
  };

  const prevSlide = () => {
    slider.current.slickPrev();
  };

  const PrevArrow = () => {
    return (
      <Prev onClick={() => prevSlide()}>
        <FontAwesomeIcon icon={faChevronLeft} color={black.default} size="lg" />
      </Prev>
    );
  };

  const NextArrow = () => {
    return (
      <Next onClick={() => nextSlide()}>
        <FontAwesomeIcon
          icon={faChevronRight}
          color={black.default}
          size="lg"
        />
      </Next>
    );
  };
  //FUNCS

  //DEFS
  const settings = {
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: images.length > 3 ? 3 : images.length,
    swipe: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 8000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  //DEFS

  return (
    <Wrapper>
      <h2>{title}</h2>
      <div className="wrapper__focus">
        <GatsbyImage
          image={images[focus]?.localFile?.childImageSharp?.gatsbyImageData}
          alt={images[focus]?.altText}
        />
      </div>
      {images.length > 1 && (
        <div className={'wrapper__slider wrapper__slider--active'}>
          <Slider {...settings} ref={slider}>
            {images.map((img, index) => {
              return (
                <div className={focus === index ? 'slide active' : 'slide'}>
                  <GatsbyImage
                    image={img?.localFile?.childImageSharp?.gatsbyImageData}
                    alt={img?.altText}
                    onClick={() => setFocus(index)}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      )}
    </Wrapper>
  );
};

export default Gallery;
