import React from 'react';

import ProductThumb from 'components/productThumb';

import copy from 'data/copy';

import { Wrapper } from './Related.styles';

const Related = (props) => {
  //PROPS
  const { data, lang } = props;
  //PROPS

  return (
    <Wrapper>
      <h2>{copy.relatedProducts[lang]}</h2>
      <div className="products-grid">
        {data.map((product, index) => {
          return (
            <ProductThumb
              data={{ ...product, ...product.product }}
              slug={product.slug}
              uri={product.seo.slug}
              key={index}
            />
          );
        })}
      </div>
    </Wrapper>
  );
};

export default Related;
