import styled from 'styled-components';

import { breakpoints } from 'utils/mixins';
import { space, zIndex } from 'theme/sizes';

export const BtnWrapper = styled.button`
  position: absolute;
  top: ${space.xs};
  right: ${space.xs};
  cursor: pointer;
  ${zIndex.above}
  padding: ${space.tiny}!important;

  svg {
    width: ${space.xs}!important;
    height: ${space.xs}!important;
  }

  @media ${breakpoints.tablet} {
    svg {
      width: ${space.xs}!important;
      height: ${space.xs}!important;
    }
  }
`;
