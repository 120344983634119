import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import FavComponent from 'components/favComp';

import copy from 'data/copy';

import { copyTextToClipboard } from 'utils/copyToClipboard';

import { Color, Wrapper } from './Description.styles';
import HTMLReactParser from 'html-react-parser';
import { GatsbyImage } from 'gatsby-plugin-image';

const Description = (props) => {
  //PROPS
  const { data, slug, lang, setExtraInfo } = props;
  //PROPS

  //DEFS
  const link = `${process.env.GATSBY_SITE.slice(0, -1)}${slug}`;
  //DEFS

  return (
    <Wrapper>
      <div className="description__header">
        <FavComponent slug={slug} />
        <h4>{data.text}</h4>
        {data?.descricao && HTMLReactParser(data.descricao)}
        {!!data?.colors?.length && (
          <div className='description__colors'>
            <p>Cores disponíveis:</p>
            <ul>
              {data.colors.map((color) => {
                console.log('color', color);
                return <Color key={color} color={color} />;
              })}
            </ul>
          </div>
        )}
        {!!data?.fillings?.list?.length && (
          <div className="thumbnail-extra">
            {data?.fillings?.info && HTMLReactParser(data?.fillings?.info)}
            <ul>
              {data.fillings?.list?.map((filling) => {
                const {
                  nome,
                  thumbnailDescription,
                  thumbnailImage,
                  thumbnailText,
                } = filling;

                return (
                  <li key={nome} className="thumbnail">
                    <div className="thumbnail-image">
                      <GatsbyImage
                        image={
                          thumbnailImage?.localFile?.childImageSharp
                            ?.gatsbyImageData
                        }
                        alt={thumbnailImage?.altText}
                      />
                    </div>
                    <div className="thumbnail-info">
                      <div className="thumbnail-text">
                        <p>{nome}</p>
                      </div>
                      <button
                        className="button"
                        onClick={() => setExtraInfo(filling)}
                      >
                        Saber mais
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {!!data?.fabrics?.list?.length && (
          <div className="thumbnail-extra">
            {data?.fabrics?.info && HTMLReactParser(data?.fabrics?.info)}
            <ul>
              {data.fabrics?.list?.map((fabric) => {
                const {
                  nome,
                  thumbnailDescription,
                  thumbnailImage,
                  thumbnailText,
                } = fabric;

                return (
                  <li key={nome} className="thumbnail">
                    <div className="thumbnail-image">
                      <GatsbyImage
                        image={
                          thumbnailImage?.localFile?.childImageSharp
                            ?.gatsbyImageData
                        }
                        objectFit="contain"
                        alt={thumbnailImage?.altText}
                      />
                    </div>
                    <div className="thumbnail-info">
                      <div className="thumbnail-text">
                        <p>{nome}</p>
                      </div>
                      <button
                        className="button"
                        onClick={() => setExtraInfo(fabric)}
                      >
                        Saber mais
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      <div className="description__social">
        <p>{copy.share[lang]}</p>
        <ul>
          <li>
            <a
              target="_blank"
              href={`http://www.facebook.com/share.php?u=${link}`}
              title="Facebook"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
          <li>
            <a
              href={`mailto:?subject=${data.title} - ${copy['share-email'][lang]} - <a style="cursor:pointer" href="${link}">${link}</a>`}
              title="Email"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </li>
          <li>
            <button onClick={() => copyTextToClipboard(link)} title="Email">
              <FontAwesomeIcon icon={faLink} />
            </button>
          </li>
        </ul>
      </div>
    </Wrapper>
  );
};

export default Description;
