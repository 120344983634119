import React from 'react';

import Description from './description';
import Gallery from './gallery';
import Info from './info';
import Related from './related';

import { Wrapper, HeaderWrapper, BodyWrapper } from './ProductCard.styles';

const ProductCard = (props) => {
  //PROPS
  const {
    lang,
    data: {
      title,
      text,
      imagens,
      descricao,
      dimensoes,
      dimensoesAdicionais,
      fillings,
      packaging,
      fabrics,
      relacionados,
      relatedFabrics,
      relatedFillings,
      colors,
    },
    slug,
    setExtraInfo,
  } = props;
  //PROPS

  return (
    <Wrapper>
      <HeaderWrapper>
        {imagens?.length && <Gallery images={imagens} title={title} />}
        <Description
          data={{
            title,
            text,
            descricao,
            colors,
            fillings: {
              list: relatedFillings?.map((e) => e.projects),
              info: fillings,
            },
            fabrics: {
              list: relatedFabrics?.map((e) => e.noticias),
              info: fabrics,
            },
          }}
          setExtraInfo={setExtraInfo}
          slug={slug}
          lang={lang}
        />
      </HeaderWrapper>
      <BodyWrapper>
        {dimensoes && (
          <Info
            lang={lang}
            data={{
              dimensoes,
              dimensoesAdicionais,
            }}
          />
        )}
        {packaging && <Info lang={lang} data={packaging} />}
        {relacionados && <Related data={relacionados} lang={lang} />}
      </BodyWrapper>
    </Wrapper>
  );
};

export default ProductCard;
