import styled from 'styled-components';

import { container, space } from 'theme/sizes';
import { breakpoints } from 'utils/mixins';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${container.small};
  width: calc(100% - 48px);
  margin: ${space.xs} auto;
`;

export const HeaderWrapper = styled.div`
  @media ${breakpoints.tablet} {
    display: flex;
    align-items: flex-start;
  }
`;

export const BodyWrapper = styled.div`
  margin-top: ${space.m};

  @media ${breakpoints.laptop} {
    margin-top: ${space.xl};
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

export const FormWrapper = styled.div`
  position: sticky;
  display: block;
  top: 0;
  left: 0;
  z-index: 50;

  @media ${breakpoints.laptop} {
    margin-top: ${space.xl};
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;
