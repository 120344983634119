import React, { useState } from 'react';

import { graphql } from 'gatsby';
import HTMLReactParser from 'html-react-parser';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';

import ProductCard from 'components/productCard';
import Popup from 'components/popup';
import ContactForm from 'components/contact-form';

import { grey } from 'theme/colors';
import { pageAnims } from 'theme/animations';

const TemplateProdutos = (props) => {
  // PROPS
  const {
    lang,
    data: {
      productPage: {
        seo: { slug, seoTitle },
        product,
      },
    },
  } = props;
  // PROPS

  // STATE
  const [extraInfo, setExtraInfo] = useState();
  // STATE

  return (
    <>
      <Popup
        show={!!extraInfo}
        toggle={() => {
          setExtraInfo(null);
        }}
      >
        <h3>{extraInfo?.nome}</h3>
        <div className="popup-image">
          <GatsbyImage
            objectFit="contain"
            image={
              extraInfo?.thumbnailImage?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            alt={extraInfo?.thumbnailImage?.altText}
          />
        </div>
        {extraInfo?.thumbnailDescription &&
          HTMLReactParser(extraInfo.thumbnailDescription)}
      </Popup>
      <motion.div
        style={{ background: grey.default, minHeight: '100vh' }}
        variants={pageAnims}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <ProductCard
          data={{ ...product, title: seoTitle }}
          slug={slug}
          lang={lang}
          setExtraInfo={setExtraInfo}
        />
      </motion.div>
      <ContactForm lang={lang} />
    </>
  );
};

export const query = graphql`
  query Produto($id: String) {
    productPage: wpProduto(id: { eq: $id }) {
      id
      seo {
        seoTitle
        slug
      }
      product {
        relacionados {
          ... on WpProduto {
            title
            seo {
              slug
            }
            product {
              text
              imagem {
                altText
                srcSet
              }
            }
          }
        }
        colors
        descricao
        dimensoes
        dimensoesAdicionais
        fillings
        relatedFabrics {
          ... on WpTecido {
            id
            noticias {
              nome
              thumbnailDescription
              thumbnailText
              thumbnailImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
              }
            }
          }
        }
        relatedFillings {
          ... on WpEnchimento {
            id
            projects {
              nome
              thumbnailDescription
              thumbnailText
              thumbnailImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
              }
            }
          }
        }
        fabrics
        packaging
        text
        imagens {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
        imagem {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                aspectRatio: 1
                placeholder: NONE
              )
            }
          }
        }
      }
    }
  }
`;

export default TemplateProdutos;
