import styled from 'styled-components';

import { toRem, breakpoints } from 'utils/mixins';

import { blue, white, black, red } from 'theme/colors';
import { space } from 'theme/sizes';
import { p } from 'theme/typography';

const COLORS = {
  red: 'red',
  'red-2': 'rgb(240,100,100)',
  green: 'green',
  blue: 'blue',
};

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .description__header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    h2 {
      width: 100%;
      position: relative;
      padding-bottom: ${space.xxs};

      &::after {
        content: '';
        height: ${toRem(2)};
        width: ${space.l};
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: ${blue.default};
      }
    }

    h4,
    p {
      display: block;
      width: 100%;
    }

    ul {
      display: flex;
      width: 100%;
      flex: 1;
      gap: 12px;
    }

    .button {
      position: absolute;
      right: ${space.xs};
      bottom: ${space.xs};
      font-size: ${toRem(14)};
      line-height: 1;

      &:hover {
        background: ${blue.default};
        color: ${white.default};
      }
    }
  }

  .description__colors {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    ul {
      display: flex;
      width: 100%;
      flex: 1;
      gap: 12px;
      flex-wrap: wrap;
    }
  }

  .description__social {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 16px;

    p {
      margin-top: 0;
    }

    ul {
      display: flex;

      li {
        margin-right: ${space.xs};

        a {
          height: ${space.s};
          min-width: ${space.s};
          line-height: 1;
        }

        svg {
          height: ${space.s};
          min-width: ${space.s};
        }
      }
    }
  }

  .thumbnail-extra {
    ul {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .thumbnail {
    max-width: ${toRem(180)};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: ${white.default};
    border-radius: ${toRem(16)};

    &-info {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: ${toRem(16)};

      .button {
        position: relative;
        padding: 4px;
        font-size: 10px;
        right: unset;
        bottom: unset;
      }
    }

    &-text {
    }

    &-image {
      position: relative;
      border-radius: ${toRem(16)};
      overflow: hidden;
      height: ${toRem(120)};
      width: 100%;
      aspect-ratio: 1;

      image,
      .gatsby-image-wrapper {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  @media ${breakpoints.tablet} {
    width: 50%;

    .description__header {
      min-height: 50vh;
      padding: ${space.m};
      padding-bottom: 64px;
    }

    .description__social {
      padding: 0 ${space.m};
    }
  }
`;

export const Color = styled.li`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 1px solid black;
  ${(props) => `background-color: ${props.color}`}
`;
