import styled from 'styled-components';

import { toRem, breakpoints } from 'utils/mixins';
import { space, zIndex } from 'theme/sizes';
import { white, blue } from 'theme/colors';

export const Wrapper = styled.div`
  width: 100%;
  padding-top: ${space.m};

  h2 {
    width: 100%;
    position: relative;
    margin-top: 24px;
    padding-bottom: ${space.xxs};

    &::after {
      content: '';
      height: ${toRem(2)};
      width: ${space.l};
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: ${blue.default};
    }
  }

  .wrapper__focus {
    .gatsby-image-wrapper {
      height: 50vh;

      img {
        object-fit: contain !important;
      }
    }
  }

  .wrapper__slider {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--active {
      display: block;

      .slick-slider {
        padding-bottom: ${toRem(80)};
      }
    }

    .slide {
      width: 100%;
      padding: ${space.xxs};
      transition: background-color 250ms;

      .gatsby-image-wrapper {
        width: 100%;

        img {
          object-fit: contain !important;
        }
      }

      /* &.active {
        background-color: rgba(0, 0, 0, 0.05);
      } */
    }
  }

  @media ${breakpoints.tablet} {
    width: 50%;
  }

  @media ${breakpoints.laptop} {
    top: 72px;
    position: sticky;
  }
`;

export const Prev = styled.button`
  position: absolute;
  bottom: ${space.s};
  right: ${toRem(60)};
  padding: ${space.s};
  left: unset !important;
  cursor: pointer;
  background-color: ${white.grey};
  ${zIndex.above}
`;

export const Next = styled.button`
  position: absolute;
  bottom: ${space.s};
  right: 0;
  padding: ${space.s};
  left: unset !important;
  cursor: pointer;
  background-color: ${white.grey};
  ${zIndex.above}
`;
