import styled from 'styled-components';

import { toRem } from 'utils/mixins';

import { space } from 'theme/sizes';

export const ProductWrapper = styled.div`
  position: relative;
  flex: 1;
  min-height: ${toRem(280)};
  max-width: ${toRem(400)};
  max-height: ${toRem(400)};
  min-width: ${toRem(280)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${space.xs};
  box-shadow: ${({ noShadow }) =>
    noShadow
      ? ''
      : 'rgba(100, 100, 111, 0.2) 0px ${toRem(7)} ${toRem(29)} 0px'};

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  img {
    aspect-ratio: 1.5;
    object-fit: contain;
  }

  .gatsby-image-wrapper {
    img {
      object-fit: contain !important;
    }
  }

  .label {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
`;
