import styled from 'styled-components';

import { breakpoints } from 'utils/mixins';

export const Wrapper = styled.div`
  width: 100%;

  .products-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  @media ${breakpoints.tablet} {
    .products-grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media ${breakpoints.laptop} {
    .products-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
`;
