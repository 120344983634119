import React from 'react';

import HTMLReactParser from 'html-react-parser';

import copy from 'data/copy';

import { Wrapper } from './Info.styles';

const Info = (props) => {
  //PROPS
  const {
    lang,
    data: { descricao, dimensoes, dimensoesAdicionais },
  } = props;
  //PROPS

  console.log(props.data);

  return (
    <Wrapper>
      {typeof props.data === 'string' ? (
        <ul className="info__list">
          <li className="info__item">
            {HTMLReactParser(props.data, {
              replace: (e) => {
                console.log(e.name);
                if (e.name === 'customimage') {
                  return (
                    <img
                      src={e.attribs.src}
                      alt={e.attribs.alt || 'Imagem personalizada'}
                    />
                  );
                }
                return e;
              },
            })}
          </li>
        </ul>
      ) : (
        <>
          <h2>{copy.productDetail[lang]}</h2>
          <ul className="info__list">
            {descricao && (
              <li className="info__item">{HTMLReactParser(descricao)}</li>
            )}

            {dimensoes && (
              <li className="info__item">
                <h3>{copy.productDetails[lang]}</h3>
                {HTMLReactParser(dimensoes)}
              </li>
            )}

            {dimensoesAdicionais && (
              <li className="info__item">
                <h3>{copy.productDimensionsAdd[lang]}</h3>
                {HTMLReactParser(dimensoesAdicionais)}
              </li>
            )}
          </ul>
        </>
      )}
    </Wrapper>
  );
};

export default Info;
