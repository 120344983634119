import styled from 'styled-components';

import { toRem, breakpoints } from 'utils/mixins';
import { p } from 'theme/typography';
import { blue } from 'theme/colors';
import { space } from 'theme/sizes';

export const Wrapper = styled.div`
  width: 100%;

  .info__item {
    position: relative;
    padding-bottom: ${space.xs};
    margin-top: ${space.xs};

    &:not(:last-child):after {
      content: '';
      height: ${toRem(2)};
      width: ${space.l};
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: ${blue.default};
    }

    h3 {
      margin-bottom: ${space.xxs};
    }

    ul {
      list-style: disc;

      li {
        ${p}
        margin-left: ${space.s};
      }
    }
  }

  @media ${breakpoints.laptop} {
    width: 100%
  }
`;
