import React from 'react';

import { Link } from 'gatsby';

import FavComponent from 'components/favComp';

import { ProductWrapper } from './ProductThumb.styles';

const ProductThumb = (props) => {
  //PROPS
  const { data, uri, noShadow } = props;
  //PROPS

  return (
    <ProductWrapper noShadow={noShadow}>
      <FavComponent {...props} />
      <Link to={uri}>
        <img srcSet={data.imagem?.srcSet} alt={data.imagem?.altText} />
        {data.title && <p className="label">{data.title}</p>}
      </Link>
    </ProductWrapper>
  );
};

export default ProductThumb;
